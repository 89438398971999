import {
  createEventHook,
  useLastChanged,
  // tryOnScopeDispose,
  useIntervalFn,
  type EventHookOn,
} from '@vueuse/core'
import { unique } from 'radash'
import { useSubscriptions } from './useSubscriptions'
import type { Notification, UserNotification } from '../../shared/types'

function useNotificationData({
  locale = 'en',
}: { locale?: MaybeRef<string> } = {}) {
  const { data: subscriptions } = useSubscriptions()
  const channels = computed(() =>
    unique(
      subscriptions.value
        .filter(({ isActive }) => isActive)
        .map(({ channelId }) => channelId),
    ),
  )

  const {
    data: response,
    status,
    refresh,
  } = useFetch<{ items: Notification[]; total: number }>(
    '/api/data/notifications',
    {
      query: { lang: locale, channels },
    },
  )

  const data = computed(() => unref(response)?.items)
  const total = computed(() => unref(response)?.total)

  useIntervalFn(refresh, 1000 * 60 * 10)

  return {
    data,
    total,
    status,
  }
}

export function useNotifications({
  locale = 'en',
}: { locale?: MaybeRef<string> } = {}) {
  const { data: notifications } = useNotificationData({ locale })

  const collectionAdd = createEventHook<UserNotification[]>()
  const collectionRemove = createEventHook()

  if (import.meta.server) {
    return useNotificationsStub({
      onAdded: collectionAdd.on,
      onRemoved: collectionRemove.on,
    })
  }

  const { $notifications } = useNuxtApp()
  const { collection } = $notifications()

  const items = computed(() => {
    const seen = collection
      .find(
        {
          dismissedAt: { $exists: true },
        },
        {
          fields: { notificationId: 1 },
          sort: { createdAt: -1 },
        },
      )
      .fetch()

    return (unref(notifications) || []).filter(
      ({ uuid }) => !seen.some(({ notificationId }) => notificationId === uuid),
    )
  })
  const lastChanged = useLastChanged(items, { deep: true })

  const hasNotification = (notificationId: string): boolean => {
    const found = collection.findOne({ notificationId })
    console.debug(
      `[useNotifications] Checking if notification ${notificationId} exists`,
      {
        found,
      },
    )
    return !!found
  }

  const mark = (
    notificationId: string,
    mark: 'seenAt' | 'dismissedAt',
  ): boolean => {
    if (!hasNotification(notificationId)) {
      collection.insert({
        notificationId,
        [mark]: new Date(),
        createdAt: new Date(),
      })
    } else {
      collection.updateOne({ notificationId }, { $set: { [mark]: new Date() } })
    }
    return true
  }

  const markSeen = (notification: UserNotification | string): boolean => {
    const notificationId =
      typeof notification === 'string'
        ? notification
        : notification.notificationId
    console.log(
      `[useNotifications] Marking notification ${notificationId} as seen`,
    )
    return mark(notificationId, 'seenAt')
  }

  const markDismissed = (notification: UserNotification | string): boolean => {
    const notificationId =
      typeof notification === 'string'
        ? notification
        : notification.notificationId
    console.log(`[useNotifications] Dismissing notification ${notificationId}`)
    return mark(notificationId, 'dismissedAt')
  }

  return {
    data: items,
    lastChanged,
    // dismissed,
    // pending,
    // isActive,
    // execute,
    markSeen,
    markDismissed,
  }
}

type UseNotificationsReturn = {
  onAdded: EventHookOn
  onRemoved: EventHookOn
}

function useNotificationsStub(defaults: UseNotificationsReturn) {
  const items = ref<Notification[]>([])
  const lastChanged = ref(new Date())

  const markSeen = (_notification: Notification | string): boolean => false

  const markDismissed = (_notification: Notification | string): boolean => false

  return {
    ...defaults,
    state: items,
    data: items,
    lastChanged,
    // dismissed,
    // pending,
    // isActive,
    // execute,
    markSeen,
    markDismissed,
  }
}
